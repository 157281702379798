export const activityKeys = {
    all: ['activities'] as const,
    lists: () => [...activityKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...activityKeys.lists(), { filters }] as const,
    details: () => [...activityKeys.all, 'detail'] as const,
    detail: (id: string) => [...activityKeys.details(), id] as const
};
export const userKeys = {
    all: ['users'] as const,
    lists: () => [...userKeys.all, 'list'] as const,
    list: (filters: string) => [...userKeys.lists(), { filters }] as const,
    details: () => [...userKeys.all, 'detail'] as const,
    detail: (id: string) => [...userKeys.details(), id] as const
};
export const activityStatusKeys = {
    all: ['activityStatuses'] as const,
    lists: () => [...activityStatusKeys.all, 'list'] as const,
    list: (filters: string) => [...activityStatusKeys.lists(), { filters }] as const,
    details: () => [...activityStatusKeys.all, 'detail'] as const,
    detail: (id: string) => [...activityStatusKeys.details(), id] as const
};
export const activityPhaseKeys = {
    all: ['activityPhases'] as const,
    lists: () => [...activityPhaseKeys.all, 'list'] as const,
    list: (filters: string) => [...activityPhaseKeys.lists(), { filters }] as const,
    details: () => [...activityPhaseKeys.all, 'detail'] as const,
    detail: (id: string) => [...activityPhaseKeys.details(), id] as const
};
export const indicatorKeys = {
    all: ['indicators'] as const,
    lists: () => [...indicatorKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...indicatorKeys.lists(), { filters }] as const,
    details: () => [...indicatorKeys.all, 'detail'] as const,
    detail: (id: string) => [...indicatorKeys.details(), id] as const
};
export const expenditureKeys = {
    all: ['expenditures'] as const,
    lists: () => [...expenditureKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...expenditureKeys.lists(), { filters }] as const,
    details: () => [...expenditureKeys.all, 'detail'] as const,
    detail: (id: string) => [...expenditureKeys.details(), id] as const
};
export const uomKeys = {
    all: ['uoms'] as const,
    lists: () => [...uomKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...uomKeys.lists(), { filters }] as const,
    details: () => [...uomKeys.all, 'detail'] as const,
    detail: (id: string) => [...uomKeys.details(), id] as const
};
export const collectionFrequencyKeys = {
    all: ['collectionFrequencys'] as const,
    lists: () => [...collectionFrequencyKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...collectionFrequencyKeys.lists(), { filters }] as const,
    details: () => [...collectionFrequencyKeys.all, 'detail'] as const,
    detail: (id: string) => [...collectionFrequencyKeys.details(), id] as const
};
export const rptKeys = {
    all: ['rpts'] as const,
    lists: () => [...rptKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...rptKeys.lists(), { filters }] as const,
    details: () => [...rptKeys.all, 'detail'] as const,
    detail: (id: string) => [...rptKeys.details(), id] as const
};
export const rpKeys = {
    all: ['rps'] as const,
    lists: () => [...rpKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...rpKeys.lists(), { filters }] as const,
    details: () => [...rpKeys.all, 'detail'] as const,
    detail: (id: string) => [...rpKeys.details(), id] as const
};
export const formMediaKeys = {
    all: ['formMedias'] as const,
    lists: () => [...formMediaKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...formMediaKeys.lists(), { filters }] as const,
    details: () => [...formMediaKeys.all, 'detail'] as const,
    detail: (id: string) => [...formMediaKeys.details(), id] as const
};
export const frequencyKeys = {
    all: ['frequencies'] as const,
    lists: () => [...frequencyKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...frequencyKeys.lists(), { filters }] as const,
    details: () => [...frequencyKeys.all, 'detail'] as const,
    detail: (id: string) => [...frequencyKeys.details(), id] as const
};
export const unitOfMeasurementKeys = {
    all: ['unitsOfMeasurement'] as const,
    lists: () => [...unitOfMeasurementKeys.all, 'list'] as const,
    list: (filters: string | { [key: string]: string }) => [...unitOfMeasurementKeys.lists(), { filters }] as const,
    details: () => [...unitOfMeasurementKeys.all, 'detail'] as const,
    detail: (id: string) => [...unitOfMeasurementKeys.details(), id] as const
};
